<template>
  <DocumentCategory :id="90000" />
</template>

<script>
import { defineComponent } from "vue";
import DocumentCategory from "@/components/DocumentCategory.vue";
export default defineComponent({
  components: { DocumentCategory },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
});
</script>
