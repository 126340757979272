<template>
  <v-card max-width="600" class="mx-auto" :loading="loading">
    <v-system-bar>Dokumente Kategorie: {{ name }}</v-system-bar>
    <v-list>
      <DocumentItem v-for="item in items" :value="item" :key="item.id" />
    </v-list>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import DocumentItem from "@/components/DocumentItem.vue";

export default defineComponent({
  components: { DocumentItem },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      name: "",
      items: [],
    };
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      const cat = await this.apiGet({
        resource: "document/category",
        id: this.id,
      });
      this.name = cat.name;
      this.items = cat.documents;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
